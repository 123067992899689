<template>
  <div class="flex p-4 bg-gray-200 rounded-2xl gap-5 items-center">
    <SportUCIcon class="h-22 w-22" />
    <div class="flex flex-col gap-1">
      <div class="text-ls font-bold">REGULAMENTOS E OUTRAS INFORMAÇÕES</div>
      <div class="text-sm text-gray-700">
        Para mais informações sobre os <b>Jogos da Universidade de Coimbra (JUC)</b>, por favor, consulte o nosso site.
      </div>
      <a href="https://www.uc.pt/desporto/juc" class="text-primary font-bold">www.uc.pt/desporto/juc</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerSportUC',
  components: {
    SportUCIcon: () => import('@/components/icons/SportUCIcon.vue'),
  },
}
</script>
